
.section__how-learn-begin .section__subsection-wrapper {
  margin-top: 0;
  padding: 20px;
}
.section__how-learn-img {
  height: 40rem;
  margin-bottom: 20px;
}
.section.section__coaches {
  min-height: auto;
  padding: 0;
}
.section__how-learn-basic {
  padding-bottom: 5rem;
}

